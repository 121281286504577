<template>

  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >

    <b-card>
      <b-table
        sticky-header="1000px"
        no-border-collapse
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template
          #cell(type)="data"
        >
          {{ ((data.item.amount > 0) ? $t('deposit'):$t('withdrawal')) }}
        </template>
        <template #cell(actions)>
          <div
            style="cursor:pointer;"
            @click="gotoPage()"
          >
            <feather-icon
              icon="UploadIcon"
              size="16"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.total"
        :per-page="pagination.perPage"
        align="fill"
        size="sm"
        class="mt-2 mb-1 mx-auto"
        style="width:max-content"
        @input="getData()"
      />
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BTable, BPagination, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import FiatApis from '@/services/apis/fiat'

const fiat = new FiatApis()
export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      show: true,
      sortBy: 'age',
      sortDesc: false,
      pagination: {
        total: 0,
        perPage: 15,
        page: 1,
      },
      fields: [
        {
          key: '#', sortable: true,
        },
        {
          key: 'amount', sortable: true,
        },
        {
          key: 'created_at', sortable: true,
        },
        {
          key: 'currency', sortable: true,
        },
        {
          key: 'type', sortable: true,
        },
        {
          key: 'ACTIONS', sortable: false,
        },
      ],
      items: [
        // {
        //   '#': '#5024', DATE: '02 Aug 2019', TOTAL: '$5285', CLIENT: '', BALANCE: '-$202', ACTIONS: '',
        // },

      ],

      file1: null,
      file2: null,
      multiSelected: [],
      visible: true,
      pageLength: 5,
      dir: false,
    }
  },

  computed: {
    ...mapGetters(['cryptoWallets']),
    rows() {
      if (this.cryptoWallets) {
        return JSON.parse(JSON.stringify(this.cryptoWallets))
      }
      return []
    },

  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.show = true
      fiat.getFiatHistory({ currency: this.$route.params.currency, page: this.pagination.page }).then(res => {
        this.items = res.data.results.data
        this.pagination.total = res.data.results.total
        this.pagination.perPage = res.data.results.per_page
        this.show = false
      })
    },
    gotoPage() {
      this.$router.push(`/${this.$i18n.locale}/customer-wallets/fiat/Invoice`)
    },
  },
}
</script>
